import {Controller} from "@hotwired/stimulus"
import Trix from "trix";
import "@rails/actiontext";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
    connect() {
        const ytButton = this.createYtButton();
        if (this.getFileToolsElement()) {
            this.getFileToolsElement().appendChild(ytButton)
        } else {
            console.log("Trix File tools element not found")
        }
    }

    async attachYT() {
        const ytID = prompt('Enter Youtube Embed ID');
        const ytAttachmentPath = `/trix_youtube_attachment/${ytID}`
        const request = new FetchRequest("get", ytAttachmentPath);
        const response = await request.perform();
        if (response.ok) {
            let attachment = new Trix.Attachment(await response.json);
            this.element.editor.insertAttachment(attachment);
        } else {
            console.error(`Error fetching ${url}`);
        }
    }

    getFileToolsElement() {
        return document.querySelector('[data-trix-button-group="file-tools"]');
    }

    createYtButton() {
        const ytButton = document.createElement('button');
        ytButton.setAttribute('type', 'button');
        ytButton.setAttribute('data-trix-attribute', 'youtube');
        ytButton.title = "Youtube";
        ytButton.className = "trix-button trix-button--icon trix-button--icon-youtube";
        ytButton.textContent = "YT";
        ytButton.addEventListener('click', this.attachYT.bind(this));
        return ytButton;
    }
}
