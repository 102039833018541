import $ from "jquery";

function mce_submit_local(form) {
  var email = form.find("input.mce-EMAIL").val();

  $(".spinner").toggleClass("hide");

  $.ajax({
    url: form.data("signupurl"),
    type: "POST",
    cache: false,
    data: {
      email: email,
      mailchimp: $("#askmailchimp").is(":checked"),
      source: form.data("source"),
      platform: form.data("platform"),
      authenticity_token: $("meta[name=csrf-token]").attr("content"),
    },
    dataType: "text",
    complete: function(res) {
      if (res && res.status === 200) {
        try {
          if (window.DEBUG === true) {
            console.log("res.responseText", res.responseText);
          }
          $(".spinner").toggleClass("hide");
          $(".succFadeOut").addClass("hide");
          mce_success_cb($.parseJSON(res.responseText));
        } catch (e) {
          if (window.DEBUG === true) {
            console.log(e);
          }
        }
      }
    },
    error: function(error) {
      if (window.DEBUG === true) {
        console.log(error);
      }
    },
  });
}

function mce_success_cb(res) {
  $(".succFadeOut").addClass("hide");
  $(".mce-response").html(res.msg).removeClass("hide");
}

$(function() {
  $("form[name='freetrial-signup-form']").submit(function(event) {
    event.preventDefault();
    var form = $(this);
    mce_submit_local(form);
  });
});
