document.addEventListener("turbo:load", () => {
  const youtubeLinks = document.querySelectorAll('a[href*="//www.youtube.com/watch?v="]');
  for (const link of youtubeLinks) {
    link.addEventListener('click', openYoutubeModal);
  }
});

function openYoutubeModal(event) {
  event.preventDefault();
  const embedURL = convertToEmbedURL(this.href);
  const modalDialog = document.createElement("div");
  modalDialog.id = "youtube-modal";
  modalDialog.setAttribute("class", "tw-relative tw-z-10");
  modalDialog.setAttribute("aria-labelledby", "Youtube modal");
  modalDialog.setAttribute("role", "dialog");
  modalDialog.setAttribute("aria-modal", "true");
  modalDialog.innerHTML = `
    <div class="tw-fixed tw-inset-0 tw-bg-black tw-transition-opacity"></div>
    <div class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto">
      <div class="tw-flex tw-items-end tw-items-center tw-justify-center tw-min-h-full tw-p-4 tw-text-center sm:tw-p-0">
        <div class="sm:tw-block tw-absolute tw-top-6 tw-right-6">
          <button type="button" class="tw-bg-black tw-border-0 tw-text-white hover:tw-text-gray-500 tw-outline-none">
            <!-- Heroicon name: outline/x -->
            <svg class="tw-h-10 tw-w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <iframe class="tw-aspect-video tw-w-full sm:tw-w-8/12" id="youtubeIframe" src="${embedURL}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
      `
  // close modalDialog on click outside of it
  modalDialog.addEventListener('click', function (event) {
    modalDialog.remove();
  });

  // close modalDialog on escape key
  // does not work, when the use interact with the youtube iframe
  document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
      modalDialog.remove();
    }
  });

  document.body.append(modalDialog);
}

function convertToEmbedURL(url) {
  const ytOptions = "?rel=0&iv_load_policy=3&showinfo=0&modestbranding=1&autoplay=1";
  return url.replace(
    /watch\?v=([^?]+).*/i,
    "embed/$1" + ytOptions
  );
}
