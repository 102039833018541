import $ from "jquery";

$(function() {
  $("form[name='mc-embedded-subscribe-form']").submit(function(event) {
    event.preventDefault();
    var form = $(this);
    form.find(".mc-embedded-subscribe").prop("disabled", true);
    $(".spinner").toggleClass("hide");

    $.ajax({
      url: "//algoriddim.us1.list-manage.com/subscribe/post-json?u=23986cdbafe38661680f0582f&id=e15238ca5a&c=?",
      type: "GET",
      cache: false,
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: $(this).serialize({
        email: form.find("input.mce-EMAIL").val(),
        source: form.data("source"),
        authenticity_token: $("meta[name=csrf-token]").attr("content"),
      }),
      success: function(res) {
        $(".spinner").toggleClass("hide");
        form.find(".mc-embedded-subscribe").prop("disabled", false);
        if (res.result === "success") {
          $(".mce-response").html(res.msg).removeClass("hide");
        }
        if (
          res.result === "error" &&
          res.msg.indexOf("already subscribed") != -1
        ) {
          $(".mce-response").html(res.msg).removeClass("hide");
        }
        $(".mce-response").removeClass("hide").html(res.msg);
        $(".notify").addClass("hide");
        if (res.result === "error") {
          $(".mce-response").addClass("error-message");
        } else {
          $(".mce-response").removeClass("error-message");
          $(".succFadeOut").addClass("hide");
        }
      },
      error: function(error) {
        $(".spinner").toggleClass("hide");
        $(".mce-response").html("Internal Error").removeClass("hide");
        form.find(".mc-embedded-subscribe").prop("disabled", false);
        if (window.DEBUG === true) {
          console.log(error);
        }
      },
    });
  });
});
