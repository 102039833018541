import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="hardware"
export default class extends Controller {
  static targets = ["mac", "ios", "win", "winArm", "android", "quest", "midinote",  "midiinfobox", "buttonMac", "buttonIos", "buttonWin", "buttonWinArm", "buttonAndroid", "buttonQuest"]
  static classes = ["active"]

  connect() {
    const os = window.location.hash.substring(1) || "mac";
    this.midiOsSwitch(os, false);
  }

  activate(e) {
    const os = e.target.getAttribute("data-os");
    this.midiOsSwitch(os, true);
    history.replaceState("", "", window.location.pathname + "#" + os);
  }

  midiOsSwitch(os, isClick) {
    switch (os) {
      case "mac":
        this.activateButton(this.buttonMacTarget);
        this.hide(this.winTargets);
        this.hide(this.winArmTargets);
        this.hide(this.androidTargets);
        this.hide(this.iosTargets);
        this.hide(this.midinoteTargets);
        this.show(this.macTargets);
        this.show(this.midiinfoboxTarget);
        break;
      case "win":
        this.activateButton(this.buttonWinTarget);
        this.hide(this.midinoteTargets);
        this.hide(this.macTargets)
        this.hide(this.winArmTargets);
        this.hide(this.iosTargets);
        this.hide(this.androidTargets);
        this.hide(this.questTargets);
        this.show(this.winTargets);
        this.show(this.midiinfoboxTarget);
        break;
      case "winArm":
        this.activateButton(this.buttonWinArmTarget);
        this.hide(this.midinoteTargets);
        this.hide(this.macTargets)
        this.hide(this.winTargets);
        this.hide(this.iosTargets);
        this.hide(this.androidTargets);
        this.hide(this.questTargets);
        this.show(this.winArmTargets);
        this.show(this.midiinfoboxTarget);
        break;
      case "ios":
        this.activateButton(this.buttonIosTarget);
        this.hide(this.midinoteTargets);
        this.hide(this.macTargets)
        this.hide(this.winTargets);
        this.hide(this.winArmTargets);
        this.hide(this.androidTargets);
        this.hide(this.questTargets);
        this.show(this.iosTargets)
        this.show(this.midiinfoboxTarget);
        break;
      case "android":
        this.activateButton(this.buttonAndroidTarget);
        this.hide(this.midinoteTargets);
        this.hide(this.midiinfoboxTarget);
        this.hide(this.macTargets)
        this.hide(this.iosTargets)
        this.hide(this.winTargets);
        this.hide(this.winArmTargets);
        this.hide(this.questTargets);
        this.show(this.androidTargets);
        break;
      case "quest":
        this.activateButton(this.buttonQuestTarget);
        this.hide(this.midinoteTargets);
        this.hide(this.midiinfoboxTarget);
        this.hide(this.macTargets)
        this.hide(this.iosTargets)
        this.hide(this.winTargets);
        this.hide(this.winArmTargets);
        this.hide(this.androidTargets);
        this.show(this.questTargets);
        break;
      default:
        console.log(`Wrong OS ${os}`);
    }
  }

  show(element) {
    if (element.length) {
      element.forEach((elem) => {
        elem.classList.remove("tw-hidden");
      });
    } else {
      element.classList.remove("tw-hidden");
    }
  }

  hide(element) {
    if (element.length) {
      element.forEach((elem) => {
        elem.classList.add("tw-hidden");
      });
    } else {
      element.classList.add("tw-hidden");
    }
  }

  activateButton(element) {
    this.deactivateButton(this.buttonMacTarget);
    this.deactivateButton(this.buttonIosTarget);
    this.deactivateButton(this.buttonWinTarget);
    this.deactivateButton(this.buttonWinArmTarget);
    this.deactivateButton(this.buttonAndroidTarget);
    this.deactivateButton(this.buttonQuestTarget);
    element.classList.add(...this.activeClasses);
  }

  deactivateButton(element) {
    element.classList.remove(...this.activeClasses);
  }
}
