//from https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/toggle.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable"];
  static values = { open: Boolean };

  connect() {
    this.toggleClass = this.data.get("class") || "hidden";
  }

  toggle(_event) {
    // event.preventDefault()
    this.openValue = !this.openValue;
  }

  hide(_event) {
    // event.preventDefault();
    this.openValue = false;
  }

  show(_event) {
    // event.preventDefault();
    this.openValue = true;
  }

  openValueChanged() {
    if (!this.toggleClass) {
      return;
    }

    this.toggleableTargets.forEach((target) => {
      target.classList.toggle(this.toggleClass);
    });
  }
}
