var DEBUG = true;

// From https://stackoverflow.com/a/9282379/1534401
// Should be executed BEFORE any hash change has occurred.
(function(namespace) {
  // Closure to protect local variable "var hash"
  if ("replaceState" in history) {
    // Yay, supported!
    namespace.replaceHash = function(newhash) {
      if (("" + newhash).charAt(0) !== "#") newhash = "#" + newhash;
      history.replaceState("", "", newhash);
    };
  } else {
    var hash = location.hash;
    namespace.replaceHash = function(newhash) {
      if (location.hash !== hash) history.back();
      location.hash = newhash;
    };
  }
})(window);

// handler to track clicks using event tracking
function track_event(category, action, label) {
  if (DEBUG) console.log("Event: " + category + " > " + action + " > " + label);
  if (typeof ga != "undefined")
    ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  if (typeof fbq != "undefined") {
    fbq("track", "ViewContent", {
      content_category: category,
      content_type: action,
      content_name: label,
    });
  }
}

function is_external() {
  if (!this.hostname || !location.hostname) return false;
  var hostname1 = this.hostname.replace(/^.*?([^\.]+\.\w+)$/, "$1");
  var hostname2 = location.hostname.replace(/^.*?([^\.]+\.\w+)$/, "$1");
  return (
    hostname1 != hostname2 || // external host
    this.href.match(/\?r=1$/) || // redirect links
    this.href.match(/\/store/)
  ); // store links
}

export function is_local() {
  if (!this.hostname || !location.hostname) return false;
  var hostname1 = this.hostname.replace(/^.*?([^\.]+\.\w+)$/, "$1");
  var hostname2 = location.hostname.replace(/^.*?([^\.]+\.\w+)$/, "$1");
  return !(hostname1 != hostname2);
}

function fix_height(selector) {
  var height = 0;

  $(selector).each(function() {
    var h = $(this).outerHeight();
    if (h > height) height = h;
  });
  $(selector).css("min-height", height);
}

// fixes height of floating list items
function fix_list_height(selector, items_per_row) {
  var row;
  $(selector + " > *").each(function(i) {
    if (i % items_per_row === 0) {
      row = $(this);
    } else {
      var prev_height = row.last().height();
      var height = $(this).height();
      row = row.add(this);
      if (height != prev_height)
        row.height(prev_height > height ? prev_height : height);
    }
  });
}

function selectTransition(newListItem) {
  var oldListItem = newListItem.parent().children().filter(".active");
  var oldImg = $(".transitionimage-" + oldListItem.index());
  var newImg = $(".transitionimage-" + newListItem.index());
  oldListItem.removeClass("active");
  newListItem.addClass("active");
  newImg.css("z-index", 2);
  oldImg.fadeOut(500, function() {
    newImg.css("z-index", 3);
    oldImg.css("z-index", 1).show();
  });
}

function nextTransition() {
  var nextItem = $("#transitions .active").next();
  if (nextItem.length === 0) nextItem = $("#transitions li:first");
  selectTransition(nextItem);
}

function hideEmptyMidis() {
  $("#midilinks > div.col-xs-12").each(function() {
    var elem = $(this);
    if (elem.find("ul").children(":visible").length === 0) {
      elem.hide();
    }
  });
}

function midiOsSwitch(os, isClick) {
  switch (os) {
    case "mac":
      $(".midinote").hide();
      $(".midinote.mac").show();
      $(".midiinfobox").show();
      $("#midilinks ul li").show();
      $("#midilinks ul .notMac").hide();
      $("#midilinks ul li span").hide();
      $("#midilinks ul .mac").show();
      break;
    case "win":
      $(".midinote").hide();
      $(".midinote.win").show();
      $(".midiinfobox").show();
      $("#midilinks ul li").show();
      $("#midilinks ul .notWin").hide();
      $("#midilinks ul li span").hide();
      $("#midilinks ul .win").show();
      break;
    case "ios":
      $(".midinote").hide();
      $(".midinote.ios").show();
      $(".midiinfobox").show();
      $("#midilinks ul li").hide();
      $("#midilinks ul li span").hide();
      $("#midilinks ul .ios").show();
      break;
    case "android":
      $(".midinote").hide();
      $(".midinote.android").show();
      $(".midiinfobox").hide();
      $("#midilinks ul li").hide();
      $("#midilinks ul li span").hide();
      $("#midilinks ul .android").show();
      hideEmptyMidis();
      break;
    default:
      // fallback to Mac in case another hash was provided
      return midiOsSwitch("mac", isClick);
  }

  // update active button
  var btn = $('.midiaction[name="' + os + '"]');
  $(".midiaction").removeClass("active");
  $("#midilinks > div.col-xs-12").show();
  $(btn).addClass("active");

  // replace URL hash
  if (isClick) {
    replaceHash(os);
  }
}

// called when the DOM is ready
$(document).on('turbo:load', function() {

  if (window.transitionImages && transitionImages.length > 0) {
    $("#transitions li").each(function(index) {
      var imgTag = $("#transitionimages img");
      if (index === 0) {
        imgTag.css({ "z-index": "3", position: "absolute" });
      } else {
        var imgUrl = transitionImages[index];
        imgTag = $("<img />")
          .attr("data-no-retina", "")
          .attr("src", imgUrl)
          .appendTo(imgTag.parent());
        imgTag.css({ "z-index": "1", position: "absolute" });
      }
      imgTag.addClass("transitionimage-" + index);

      $(this).mouseenter(function() {
        selectTransition($(this));
      });
    });
    // cycle timer
    var interval = 4000;
    var timer = setInterval(nextTransition, interval);
    $("#transitions").hover(
      function() {
        window.clearInterval(timer);
      },
      function() {
        timer = setInterval(nextTransition, interval);
      }
    );
  }

  $(".library-switch").on("click", function(e) {
    $(".library-image").toggleClass("library-image-hidden");
    $(".library-switch").toggleClass("library-image-hidden");
  });

  // track external links and redirects
  $("a")
    .filter(is_external)
    .attr("target", "_blank")
    .click(function() {
      track_event("Outgoing Links", "Click", this.href);
    });

  // track all downloads
  $("a:not(.thickbox)")
    .filter(function() {
      return this.href.match(/\.(jpg|jpeg|gif|png|tif|tiff|eps|pdf|dmg|zip)$/i);
    })
    .filter(is_local)
    .click(function() {
      track_event(
        "Downloads",
        "Click",
        this.href.replace(/^[a-z]+:\/\/[^\/]+/, "")
      );
    });

  // add title tag to all fixed-width tables
  $("table.fixed-width td").each(function() {
    if (!$(this).attr("title")) $(this).attr("title", $(this).text());
  });

  //streaming migration android
  fix_height(".infobox.half > p");

  //neural
  fix_height(".neuralartists .artistwrap");

  //djschool episode height
  var episodeHeight = 0;
  $(".type-school .episode").each(function(index) {
    if (index % 2 === 0) {
      episodeHeight = $(this).children("p").height();
    }
    $(this).children("p").height(episodeHeight);
  });

  // midi controller anchor
  var midiHash = $(location).attr("hash");
  midiOsSwitch(midiHash.substring(1), false);

  // midi controller select function
  $(".midiaction").on("click", function(e) {
    midiOsSwitch($(this).attr("name"), true);
  });

  /* facebook sdk */
  /*(function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk')); */
});

if (typeof console == "undefined") {
  console = {};
  console.log = function(msg) {
    alert(msg);
  };
}
