import {Controller} from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import {patch} from '@rails/request.js'

// Connects to data-controller="sortable"
export default class extends Controller {
  static values = {view: String}
  static targets = ["positionNumberWrapper"]

  connect() {
    if (this.viewValue != "index") {
      return
    }
    this.updatePositions();

    const table = document.querySelector('[data-selected-resources-name="media_catalog_item_per_playlists"] tbody')
    this.sortable = new Sortable(table, {
      animation: 150,
      onUpdate: this.onUpdate.bind(this),
      handle: '[data-resource-index-target="positionNumberWrapper"]',
    })
  }

  updatePositions() {
    this.positionNumberWrapperTargets.forEach((element, index) => {
      element.innerHTML = `${index + 1} &nbsp;&nbsp&nbsp; ↕`
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  async onUpdate({item, newIndex}) {
    const resourceId = item.dataset.resourceId;
    const data = new FormData()
    data.append("position", newIndex + 1)
    const url = `${window.Avo.configuration.root_path}/resources/media_catalog_item_per_playlists/update_position/${resourceId}`
    await patch(url, {body: data, responseKind: "html"})
    this.updatePositions();
  }
}
