import { Controller } from "@hotwired/stimulus"
import {post} from "@rails/request.js"

// Connects to data-controller="djschool-progress"
export default class extends Controller {
  static values = {
    apiPath: String,
    iconId: String,
    iconPlayedPath: String,
    iconUnplayedPath: String,
    iconCurrentPath: String,
    status: String,
  }

  connect() {
    this.handleVideoEnded = this.handleVideoEnded.bind(this);
    this.handleVideoPlaying = this.handleVideoPlaying.bind(this);
    this.element.addEventListener("ended", this.handleVideoEnded);
    this.element.addEventListener("playing", this.handleVideoPlaying);
    this.initialStatus = this.statusValue;
    console.log(`initialStatus=${this.statusValue}`)
  }

  disconnect() {
    console.log("Video is disconnected")
    this.element.pause(); // stop playing video
    this.element.removeEventListener("playing", this.handleVideoPlaying);
    this.element.removeEventListener("ended", this.handleVideoEnded);
    if (this.statusValue === "playing") {
      this.setIcon(this.initialStatus);
    }
  }

  setIcon(status) {
    const iconElement = document.getElementById(this.iconIdValue);
    if (status === "played") {
      iconElement.src = this.iconPlayedPathValue;
      console.log("Video marked as watched");
    } else if (status === "playing") {
      iconElement.src = this.iconCurrentPathValue;
      console.log("Video is playing");
    } else if (status === "unplayed") {
      iconElement.src = this.iconUnplayedPathValue;
      console.log("Video is paused");
    } else {
      console.log(`Invalid status value=${this.statusValue}`);
    }
  }

  statusValueChanged() {
    this.setIcon(this.statusValue);
  }

  handleVideoPlaying() {
    console.log("Video is playing")
    this.statusValue = "playing";
  }

  async handleVideoEnded() {
    console.log("Video has ended")
    this.statusValue = "played";
    await post(this.apiPathValue, {})
  }
}
