import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = ["main", "thumbnail"];
  static values = {transitionDuration: Number}
  static classes = ["selected"];

  connect() {
    this.mainTarget.style.transitionProperty = 'opacity';
    this.mainTarget.style.transitionTimingFunction = 'cubic-bezier(.4,0,.2,1)';
    this.mainTarget.style.transitionDuration = `${this.transitionDurationValue}ms`;
  }

  selectImage(event) {
    const src = event.currentTarget.src;
    this.loadImage(src);
    this.updateSelectedThumbnail(event.currentTarget);
  }

  loadImage(src) {
    const image = new Image();
    image.onload = () => {
      setTimeout(() => {
        this.mainTarget.src = src;
        this.mainTarget.style.opacity = 1;
      }, this.transitionDurationValue);
    }
    image.src = src;
    this.mainTarget.style.opacity = 0.1;
  }

  updateSelectedThumbnail(selectedThumbnail) {
    this.thumbnailTargets.forEach(thumbnail => {
      this.selectedClasses.forEach(className => {
        thumbnail.classList.remove(className);
      })
    });
    this.selectedClasses.forEach(className => {
      selectedThumbnail.classList.add(className);
    });
  }
}
