import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = [ "source", "button" ]
  static values = {
    successContent: {
      type: String,
      default: "Copied!",
    },
    successDuration: {
      type: Number,
      default: 2000,
    },
  }

  connect() {
    if (!this.hasButtonTarget) return
    this.originalContent = this.buttonTarget.innerHTML
  }

  copy() {
    // this.sourceTarget.select()
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.value).then(() => this.copied())
  }

  copied(){
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = this.successContentValue;

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent;
    }, this.successDurationValue)
  }
}
