/* eslint no-console:0 */
// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers";

// import "stylesheets/application";
import "jquery-ujs";

import "./lib/freetrial_signup";
import "./lib/header";
import "./lib/jquery-global";
import "./lib/mailchimp_signup";
import "./lib/main";
// import * as bootstrap from "bootstrap"

// // import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import "./lib/youtube";
