import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="djschool-lesson"
export default class extends Controller {
  static values = {localEnv: Boolean}

  connect() {
    if (this.localEnvValue) {
      window.addEventListener("playFreeLessonBlocked", () => alert("Subscription required"));
    }
  }

  subscriptionRequired(event) {
    event.preventDefault();
    const customEvent = new Event('playFreeLessonBlocked');
    window.dispatchEvent(customEvent);
  }
}
