import $ from "jquery";

$(document).on('turbo:load', function() {
  // toggle mobile menu
  $("#hamburger").click(function() {
    $(".main-nav").slideToggle({ duration: 400 });
    $(".social-bar").slideToggle({
      duration: 200,
      start() {
        $(".social-bar").css("display", "flex");
      }
    });
  });
});
